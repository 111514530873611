import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { vendorLoginContext } from '../../Context/vendorLogin';
import pirmLogo from '../../images/pirmLogo.png';
import css from './style.module.css';
import { changePasswordSchema } from '../../lib/Yup Validation/VendorsSchemas';
import { DomainSwitchContext } from '../../Context/DomainSwitch.Context';
import LoadingForget from '../LoadingPages/LoadingForget';

export default function ForgetPassword() {
    const { changePassword, otpCodeNumber } = useContext(vendorLoginContext);
    const { logo } = useContext(DomainSwitchContext);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({ password: '' });
    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [otp, setOtp] = useState(['', '', '', '']); // OTP state

    const inputRef = useRef([]);
    useEffect(() => {
        if (logo === null)
            setLoading(true); // Show LoadingOtp if logo is null
        else
            setLoading(false);
    }, [logo]);

    if (loading) {
        return <LoadingForget />;
    }


    const handleChange = (e, index) => {
        const value = e.target.value;
        if (value === '' || /^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value && index < otp.length - 1) {
                inputRef.current[index + 1].focus();
            }
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
            inputRef.current[index - 1].focus();
        }
    };

    const getData = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const login = async () => {
        setError('');
        setSpinner(true);
        try {
            const otpString = otp.join('');
            const validationData = {
                password: data.password,
                otp: otpString,
            };

            await changePasswordSchema.validate(validationData, { abortEarly: false });


            // Compare OTP values
            if (otpCodeNumber.otpCode.toString() === otpString) {
                const { password } = data;
                const res = await changePassword(otpCodeNumber?.email, password, otpString, otpCodeNumber.expiry);

                if (res.status === 200) {
                    navigate('/vendorlogin');
                } else {
                    setError('Password change failed.');
                }
            } else {
                setError('Invalid OTP');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setError(error.errors ? error.errors.join(", ") : 'An unexpected error occurred');

        } finally {
            setSpinner(false);
        }
    };

    return (
       
            <div className="border border-2 rounded documents">
                <header className="text-primary py-3 fw-bold px-5 mt-2 border-bottom border-2 rounded-top text-center">
                    <img src={logo} alt="Company logo" className={css.logo} />
                </header>
                <main className="py-3 text-center px-4">
                    <p className="text-center fw-bold mt-2 mb-2 " style={{ fontWeight: 400, fontSize: "22px" }}>
                        Verify Your Identity
                    </p>
                    <p className='mb-3  ms-1' style={{ width: "240px", fontWeight: 400, fontSize: "14px" }}>Please check your email for the MFA code and enter it below</p>

                    <label className="d-flex justify-start mb-2 ms-2">New Password</label>
                    <input
                        type="password"
                        name="password"
                        className="form-control inputStyle"
                        onChange={getData}
                        placeholder="password"
                    />
                    <label htmlFor="otp" className="d-flex justify-start my-2 ms-2">MFA Code</label>
                    <div className="d-flex justify-content-between">
                        {otp.map((value, index) => (
                            <input
                                key={index}
                                type="text"
                                id={`otp-${index}`}
                                name="otp"
                                value={value}
                                maxLength="1"
                                className={css.otpInput}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleBackspace(e, index)}
                                ref={(el) => (inputRef.current[index] = el)}
                                placeholder="X"
                            />
                        ))}
                    </div>
                    <button
                        onClick={login}
                        className="btn text-white my-4 mx-3 px-5 py-2 rounded-1"
                        style={{ backgroundColor: "#2268DE" }}
                        disabled={spinner}
                    >
                        Create Password
                        {spinner && <i className="ms-3 fas fa-spinner fa-pulse"></i>}
                    </button>
                    {error && (
                        <div className="d-flex justify-content-center">
                            <div className="alert alert-danger" style={{ width: "80%" }}>
                                {error}
                            </div>
                        </div>
                    )}

                </main>
            </div>
        
    );
}
