import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { SalesforceContext } from "./SF.CredantialsContext";

export const VendorContext = createContext();

export default function VendorProvider({ children }) {
  const { tokens, fetchCredentials } = useContext(SalesforceContext);
  const [Vendors, setVendors] = useState([]);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(() => {
    try {
      return localStorage.getItem("User Email") || null;
    } catch {
      return null;
    }
  });

  useEffect(() => {
    if (tokens && email) {
      getVendors(email, tokens);
    }
  }, [email, tokens]);

  const getVendors = async (email, token) => {
    try {
      const response = await axios.post(
        "https://api-skhwrpqgyq-uc.a.run.app/vendorlist",
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage(response.data.message);
      setVendors(response.data.data);
    } catch (error) {
      if (
        error.response?.status === 401 &&
        error.response?.data?.message === "Token expired"
      ) {
        try {
          const refreshedToken = await fetchCredentials(); // Refresh the token
          if (refreshedToken) {
            await getVendors(email, refreshedToken); // Retry the request with the refreshed token
          }
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          setMessage("Failed to refresh token. Please log in again.");
          setError(refreshError);
        }
      } else {
        const errorMessage =
          error.response?.data?.message || "Error fetching vendors";
        console.error("Error fetching vendors:", errorMessage);
        setMessage(errorMessage);
        setError(error.response || error);
        setVendors([]); // Clear vendors on error
      }
    }
  };
  const resetVendorContext = () => {
    setVendors([]);
    setMessage(null);
    setError(null);
  };

  return (
    <VendorContext.Provider value={{ Vendors, message, error, setEmail,resetVendorContext }}>
      {children}
    </VendorContext.Provider>
  );
}
