import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export default function Protected({ children }) {
    const location = useLocation();
 
    if (location.pathname === "/vendors" || location.pathname === "/questionnaires" || location.pathname === "/forgetpassword" || location.pathname === "/otp") {
        const userId = localStorage.getItem('Vendor User');
        return userId ? children : <Navigate to="/vendorlogin" />;
    }
    const sessionId = localStorage.getItem('sessionId');
    return sessionId ? children : <Navigate to="/" />;
}