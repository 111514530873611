import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AppContext = createContext([]);

export default function AppProvider({ children }) {
  const [app, setApp] = useState([]);
  const tokenStorage = JSON.parse(localStorage.getItem("okta-token-storage"));
  const expiresAt = tokenStorage?.accessToken?.expiresAt;
  const navigate = useNavigate(); // Initialize navigate for redirection

  const session = (expireIn = 3600) => {
    let expirationDate;

    if (expiresAt) {
      expirationDate = new Date(expiresAt * 1000); // Ensure `expiresAt` is in seconds
    
      
    } else if (expireIn) {
      expirationDate = new Date(Date.now() + expireIn * 1000); // Convert `expireIn` to ms
      
    } else {
      console.warn("No valid expiration time provided.");
      return;
    }

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const timeoutDuration = (expirationDate.getTime() / 1000 - currentTimeInSeconds) * 1000;

    if (timeoutDuration <= 0) {
      console.warn("Token has already expired.");
      localStorage.removeItem("okta-token-storage");
      localStorage.removeItem("okta-cache-storage");
      localStorage.removeItem("sessionId");
      navigate("/"); // Redirect immediately
      return;
    }

    const sessionId =
      Math.random().toString(36).slice(2) +
      Math.random().toString(36).toUpperCase().slice(2);

    localStorage.setItem("sessionId", sessionId);

    const timeoutId = setTimeout(() => {
      localStorage.removeItem("okta-token-storage");
      localStorage.removeItem("okta-cache-storage");
      localStorage.removeItem("sessionId");
      navigate("/"); // Redirect when session ends
    }, timeoutDuration);

    return () => clearTimeout(timeoutId);
  };

  return (
    <AppContext.Provider value={{ app, setApp, session }}>
      {children}
    </AppContext.Provider>
  );
}
