import React, { useState, useEffect, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import pirmLogo from '../../images/pirmLogo.png';
import css from './style.module.css';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import FirebaseAuthForm from "./FirebaseAuthForm";

const Login = () => {
  const { setApp, session } = useContext(AppContext);
  const { authState, oktaAuth } = useOktaAuth();
  const [assignedApps, setAssignedApps] = useState('');
  const [error, setError] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const navigate = useNavigate();
  const companyLogo = localStorage.getItem("Company Logo")

  useEffect(() => {
    const checkAuthentication = async () => {
      if (authState?.isAuthenticated && authState.accessToken) {
        setSpinner(true);
        try {
          const info = await oktaAuth.getUser();


          const Application = await getAssignedApplications(info.sub);


          if (Application.length !== 0) {
            session()
          }

          setApp(Application);
          if (Application.length > 0) {
            navigate('/home');
          } else {
            setAssignedApps("You don't have access to this application. Please contact your Okta Administrator.");
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
          setError("Error fetching user info");
        } finally {
          setSpinner(false);
        }
      } else {
        setAssignedApps(null);
      }
    };

    checkAuthentication();
  }, [authState, oktaAuth, navigate]);

  const getAssignedApplications = async (user) => {
    const tokenStorage = localStorage.getItem("okta-token-storage");

    if (!tokenStorage) {
      console.error("No token storage found.");
      setError("No token storage found.");
      return [];
    }

    const { accessToken } = JSON.parse(tokenStorage);

    if (!accessToken) {
      console.error("No access token found.");
      setError("No access token found.");
      return [];
    }

    setSpinner(true);

    try {
      const response = await axios.post('//pirm-portal.uc.r.appspot.com/react', { userId: user });
      return response.data.Assigned_Applications;
    } catch (error) {
      console.error("Error fetching assigned applications:", error);
      setError("Error fetching assigned applications");
      setSpinner(false);
      return [];
    }
  };


  const login = () => {
    setLoginStatus(true);
    oktaAuth.signInWithRedirect({ originalUri: "/" });
  }

  return (
   
      <div className="border border-2 rounded documents">
        <header className="text-primary py-3 fw-bold px-5 mt-2 border-bottom border-2 rounded-top text-center">
          <img src={companyLogo || pirmLogo} alt="Company logo" className={css.logo} />
        </header>
        <main className="py-3 text-center px-4">
          <p className="text-center fw-bold mt-4 mb-2" style={{ fontWeight: 400, fontSize: "20px" }}>
            Compliance Portal
          </p>
          {/* {error && <p className="text-danger fw-bold mt-2 mb-0">{error}</p>} */}
          <div className="my-3">
            <FirebaseAuthForm loginStatus={loginStatus} setLoginStatus={setLoginStatus} session={session} />

          </div>
          {assignedApps && <p className="text-danger fw-bold px-4">{assignedApps}</p>}
          <span id="Line" className={css.loginLine}>or sign in with</span>
          <button
            onClick={login}
            className="btn text-white my-4 rounded-1 w-100"
            style={{ backgroundColor: "#27374d" }}
            disabled={loginStatus} // Disable based on loginStatus
          >
            OKTA
            {spinner && <i className="ms-3 fas fa-spinner fa-pulse"></i>}
          </button>
        </main>
      </div>
    
  );
};

export default Login;
