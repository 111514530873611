import React, { useContext, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { MarktingContext } from "../../Context/MarktingContext";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDropzone } from "react-dropzone";

export default function AddMarktingForm({ animationPlayed }) {
    const { setMarktingData } = useContext(MarktingContext);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState('')

    useEffect(() => {
        if (animationPlayed) {
            setFiles([]);  // Clear files when animation plays
        }
    }, [animationPlayed]);


    useEffect(() => {
        if (JSON.parse(localStorage.getItem('okta-token-storage'))?.idToken) {
            setUserName(JSON.parse(localStorage.getItem('okta-token-storage'))?.idToken?.claims?.name)
        } else {
            setUserName(localStorage.getItem('User Name'))
        }

    }, []);


    // Validation schema
    const validationSchema = Yup.object({
        documentName: Yup.string().required("Document Name is required."),
        file: Yup.mixed()
            .required("A file is required.")
            .test(
                "fileType",
                "Invalid file type. Only images, PDFs, or Office files are allowed.",
                (value) =>
                    value &&
                    [
                        "image/jpeg", "image/webp", "image/png", "image/gif",
                        "application/pdf", "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/vnd.ms-excel",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/vnd.ms-powerpoint",
                        "text/html",
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                    ].includes(value.type)
            ),
    });

    // Handle file upload
    const uploadFiles = async (values, resetForm) => {
        setLoading(true);

        try {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = async (event) => {
                const base64File = event.target.result.split(",")[1]; // Remove base64 prefix
                const payload = {
                    documentName: values.documentName,
                    created_by: userName && userName,
                    fileName: file.name,
                    mimeType: file.type,
                    fileData: base64File, // File content as base64
                };
                const { data } = await axios.post(
                    "https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/marketing",
                    payload,
                    { headers: { "Content-Type": "application/json" }, timeout: 60000 }
                );

                setMarktingData((prevData) => [...prevData, data.data]);
                setLoading(false);
                setFiles([]);  // Clear files after upload
                resetForm()
            };
            reader.readAsDataURL(file);
        } catch (error) {
            console.error("Error uploading files:", error.response || error.message);
            setLoading(false);
        }
    };

    // Handle drag and drop
    const onDrop = (acceptedFiles, setFieldValue) => {
        if (acceptedFiles.length > 0) {
            setFiles(acceptedFiles);
            setFieldValue("file", acceptedFiles[0]); // Sync with Formik's state
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => onDrop(acceptedFiles), // Pass setFieldValue correctly
        accept: [
            "image/*",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-powerpoint",
            "text/html",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ],
    });

    return (
        <AnimatePresence>
            {animationPlayed && (
                <motion.div
                    initial={{ height: 0, opacity: 0, overflow: "hidden" }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{
                        height: { duration: 0.8, ease: "easeInOut" },
                        opacity: { duration: 0.5, ease: "easeInOut" },
                        delayChildren: 0.3,
                        staggerChildren: 0.1,
                    }}
                >
                    <Formik
                        initialValues={{
                            documentName: "",
                            created_by: "",
                            file: null,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            try {
                                await uploadFiles(values, resetForm); // Upload the files
                            } catch (error) {
                                console.error("Error during file upload:", error);
                            } finally {
                                setSubmitting(false); // End the submitting state
                            }
                        }}
                    >
                        {({ isSubmitting, errors, touched, setFieldValue }) => (
                            <Form>
                                {/* Form Fields */}
                                <div className="m-2">
                                    <label htmlFor="documentName">Document Name:</label>
                                    <Field
                                        className="form-control"
                                        type="text"
                                        name="documentName"
                                        placeholder="Enter your Document Name"
                                    />
                                    {errors.documentName && touched.documentName && (
                                        <div style={{ color: "red" }}>{errors.documentName}</div>
                                    )}
                                </div>

                                {/* Drag-and-Drop Zone */}
                                <div
                                    {...getRootProps()}
                                    className={`dropzone ${isDragActive ? "active" : ""} m-2`}
                                    style={{
                                        border: "2px dashed #ccc",
                                        padding: "20px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    <input
                                        {...getInputProps()}
                                        onChange={(event) => {
                                            const file = event.target.files[0];
                                            setFiles([file]); // Update local state
                                            setFieldValue("file", file); // Sync with Formik's state
                                        }}
                                    />
                                    {isDragActive ? (
                                        <p>Drop the files here...</p>
                                    ) : (
                                        <p>Drag and drop files here, or click to select files.</p>
                                    )}
                                </div>

                                {errors.file && touched.file && (
                                    <div style={{ color: "red" }}>{errors.file}</div>
                                )}

                                {/* Display Selected Files */}
                                {files.length > 0 && (
                                    <ul>
                                        {files.map((file, index) => (
                                            <li key={index}>
                                                {file.name} ({Math.round(file.size / 1024)} KB)
                                            </li>
                                        ))}
                                    </ul>
                                )}

                                <button
                                    type="submit"
                                    disabled={isSubmitting || loading}
                                    className="btn btn-success m-2"
                                >
                                    {loading ? "Uploading..." : "Upload"}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
