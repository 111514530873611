import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ProgressIndicator from './ProgressIndicator';
import css from './style.module.css';
import AddMarktingForm from './AddMarktingForm';
import DocumentComments from './DocumentComments';
import Header from './Header';
import AnnotationApp from './AnnotationApp'

export default function MarketingDetails() {
    const location = useLocation();
    const { data } = location.state || {};
    const [comments, setComments] = useState([]);
    const [currentComment, setCurrentComment] = useState(null);
    const [annotations, setAnnotations] = useState([]);
    const [selectedElement, setSelectedElement] = useState(null);
    const [docInfo, setDocInfo] = useState({ documentUrl: [] });
    const [animationPlayed, setAnimationPlayed] = useState(false);

    const isImage = docInfo?.documentUrl[0]?.match(/\.(jpeg|jpg|gif|png|bmp)$/i);
    const isHtml = docInfo?.documentUrl[0]?.match(/\.html$/i);

    useEffect(() => {
        if (data) {
            setDocInfo(data);
        }
    }, [data]);

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin === "https://storage.cloud.google.com") {
                setSelectedElement(event.data);
            }
        };

        window.addEventListener("message", handleMessage);
        return () => window.removeEventListener("message", handleMessage);
    }, []);

    if (!docInfo?.documentUrl.length) {
        return <p>Loading...</p>;
    }

    const handleImageClick = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = Math.max(0, Math.min(e.pageX - rect.left, rect.width));
        const y = Math.max(0, Math.min(e.pageY - rect.top, rect.height));
        setCurrentComment({ x, y, text: "" });
    };

    const handleCommentSubmit = () => {
        if (currentComment?.text.trim()) {
            setComments([...comments, currentComment]);
            setCurrentComment(null);
        }
    };

    return (
        <div className="container-fluid mt-3 ms-3">
            <Header docInfo={docInfo} animationPlayed ={animationPlayed} setAnimationPlayed={setAnimationPlayed}/>
            <AddMarktingForm animationPlayed={animationPlayed} />

            <ProgressIndicator />
            <div className="row d-flex">
                <div className="col-md-9 vh-100">
                    {isImage ? (
                        <div style={{ position: "relative", width: "100%", height: "100%" }}>
                            <img
                                src={docInfo?.documentUrl[0]}
                                alt="file"
                                className="img-fluid rounded-4 w-100 h-100 contain"
                                onClick={handleImageClick}
                                style={{ cursor: "crosshair" }}
                            />
                            {comments.map((comment, index) => (
                                <div
                                    key={index}
                                    style={{
                                        position: "absolute",
                                        left: `${comment.x}px`,
                                        top: `${comment.y}px`,
                                        transform: "translate(-50%, -50%)",
                                        backgroundColor: "red",
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                    }}
                                    title={comment.text}
                                />
                            ))}
                            {currentComment && (
                                <div
                                    style={{
                                        position: "absolute",
                                        left: `${currentComment.x}px`,
                                        top: `${currentComment.y}px`,
                                        transform: "translate(-50%, -50%)",
                                        backgroundColor: "white",
                                        border: "1px solid black",
                                        padding: "5px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <input
                                        type="text"
                                        value={currentComment.text}
                                        onChange={(e) =>
                                            setCurrentComment({ ...currentComment, text: e.target.value })
                                        }
                                        placeholder="Add a comment"
                                    />
                                    <button onClick={handleCommentSubmit}>Save</button>
                                </div>
                            )}
                        </div>
                    ) :
                        //  isHtml ? (
                        //     <AnnotationApp documentUrl={docInfo?.documentUrl[0]} />
                        // ) :
                        (
                            <iframe
                                src={docInfo?.documentUrl[0]}
                                width="100%"
                                height="100%"
                                title="File Viewer"
                                className="rounded-4 px-1 py-2"
                            />
                        )}
                </div>
                <div className="col-md-3">
                    <DocumentComments />
                </div>
            </div>
        </div>
    );
}
