import React from 'react'
import pirm from '../../images/pirmLogo.png'
import css from'./style.module.css'
export default function Footer() {
  return (
    <div className='d-flex w-100 justify-content-center align-content-center prevent-select  ' >
        <p className={css.footerP}>Powered by Predictive IRM, LLC</p>
        <img src={pirm}  className={css.footerImg} alt="" />
    </div>
  )
}