import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import css from '../Login/style.module.css';


export default function LoadingForget() {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center min-vh-100" >
                <div className="border border-2 rounded documents">
                    <header className="text-primary py-3 fw-bold px-5 mt-2 border-bottom border-2 rounded-top text-center">
                        <Skeleton baseColor="#EBE6F0" height={50} width={150} />
                    </header>
                    <main className="py-3 text-center px-4">
                        <p className="text-center fw-bold mt-4 mb-2" style={{ fontWeight: 400, fontSize: "20px" }}>
                            <Skeleton baseColor="#EBE6F0" />

                        </p>
                        <Skeleton baseColor="#EBE6F0" className="form-control inputStyle my-3" />

                        <Skeleton baseColor="#EBE6F0" className="form-control inputStyle my-3" />

                        <label htmlFor="otp" className="d-flex justify-start my-2 ms-2">MFA Code</label>
                        <div className="d-flex justify-content-between">
                            {Array(4).fill(0).map((value, index) => (
                                <Skeleton baseColor="#EBE6F0" className={`${css.otpInput} mx-2`} />

                            ))}
                        </div>
                        <button
                            className="btn text-white my-4 mx-3 px-5 py-2 rounded-1"
                            style={{ backgroundColor: "#2268DE" }}
                        >
                            <Skeleton baseColor="#EBE6F0" width={80} />
                        </button>
                    </main>
                </div>
            </div>
        </>
    )
}
