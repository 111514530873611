import CryptoJS from "crypto-js";
const SECRET = process.env.REACT_APP_SECRET_KEY;

const Encryption = (data) => {
  const Encryption = CryptoJS.AES.encrypt(data, SECRET).toString();
  return Encryption;
};
const Decryption = (data) => {
  try {
    const SECRET = process.env.REACT_APP_SECRET_KEY;

    const decryptedBytes = CryptoJS.AES.decrypt(data, SECRET);

    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedData) {
      throw new Error("Decryption failed. The data might be incorrect.");
    }
   

    return JSON.parse(decryptedData);
  } catch (error) {
    // console.error("Error during decryption:", error.message);
    return null;
  }
};

export { Decryption, Encryption };
