import React, { useState } from 'react';
import css from './style.module.css';
import { AnimatePresence, motion } from 'framer-motion';

export default function Header({ docInfo, animationPlayed, setAnimationPlayed }) {
    const [listToggle, setListToggle] = useState(false);

    return (
        <div className={`container-fluid ${css.documetContainer} py-1`}>
            <div className="row">
                <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className='d-flex align-items-center'>
                        <p className='text-center my-auto fw-bold fs-5'>{docInfo?.documentName} - V{docInfo?.latestVersion}</p>
                        <div className='position-relative z-3'>
                            <motion.div
                                initial={{ rotateX: 0 }}
                                animate={{ rotateX: listToggle ? 180 : 0 }}
                                transition={{ duration: 0.9, ease: "easeInOut" }}
                            >
                                <i className="ms-2 fa-regular fa-square-caret-down fa-xl holdCursor" onClick={() => setListToggle(!listToggle)}></i>
                            </motion.div>

                            <AnimatePresence>
                                {listToggle && (
                                    <motion.div
                                        initial={{ height: 0, opacity: 0, overflow: "hidden" }}
                                        animate={{ height: "auto", opacity: 1 }}
                                        exit={{ height: 0, opacity: 0 }}
                                        transition={{
                                            height: { duration: 0.8, ease: "easeInOut" },
                                            opacity: { duration: 0.5, ease: "easeInOut" },
                                            delayChildren: 0.3,
                                            staggerChildren: 0.1,
                                        }}
                                        className={`${css.versionList} d-flex justify-content-center align-items-center flex-column`}
                                    >
                                        <>
                                            <p className={`m-0 ${css.versionListVersion} w-100 text-center`}>Version 2</p>
                                            <p className={`m-0 ${css.versionListVersion} w-100 text-center`}>Version 3</p>
                                            <p className={`m-0 ${css.versionListVersion} w-100 text-center`}>Version 4</p>
                                            <p className={`m-0 ${css.versionListVersion} w-100 text-center`}>Version 5</p>
                                        </>
                                    </motion.div>
                                )}
                            </AnimatePresence>

                        </div>
                    </div>
                    {animationPlayed ? (
                        <button className='btn btn-danger text-white' onClick={() => setAnimationPlayed(false)}>Cancel</button>
                    ) : (
                        <button className='btn btn-primary' onClick={() => setAnimationPlayed(true)}>New Version</button>
                    )}
                </div>
                <div className="col-md-12"></div>
            </div>
        </div >
    );
}
