import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { Decryption } from "../lib/Decryption";
import { SalesforceContext } from "./SF.CredantialsContext";
import { useOktaConfig } from "./CredentialsContext";

export const DomainSwitchContext = createContext(null);

export default function DomainSwitchProvider({ children }) {
  const { setTokens } = useContext(SalesforceContext);
  const { setOktaConfig } = useOktaConfig();

  // Retrieve logo from localStorage initially, or set it to null if not found
  const [logo, setLogo] = useState(
    () => localStorage.getItem("Company Logo") || null
  );

  const testFunction = async () => {
    const { protocol, hostname, port } = window.location;
    let fullUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}/`;
    // Send the company domain to the server
    let redirectUriLocal = null;
    if (fullUrl === "http://localhost:3000/") {
      fullUrl = "https://policy-portal-4ca94.web.app/";
      redirectUriLocal = "http://localhost:3000/login/callback";
    }
    // console.log(redirectUriLocal);

    try {
      const res = await axios.post(
        `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/companies?companyDomain=${fullUrl}`
      );
      const { data, status } = await axios.get(
        `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/credentials`,
        {
          headers: {
            Authorization: `Bearer ${res.data.data}`,
          },
        }
      );

      const id = Decryption(data.data._id);

      if (res.status === 200 && status === 200) {
        setTokens(res.data.data);
        localStorage.setItem("Token", JSON.stringify(res.data.data));
        localStorage.setItem("CompanyName", data?.data.companyName);

        const newLogo = data.data.logo;
        // setLogo(newLogo); // Update state
        setLogo(newLogo);
        localStorage.setItem("Company Logo", newLogo); // Update localStorage
        setOktaConfig({
          clientId: id.oktaClientID,
          issuer: `https://${data.data.oktaDomain}/oauth2/default`,
          redirectUri: redirectUriLocal
            ? redirectUriLocal
            : fullUrl + "login/callback",
          scopes: ["openid", "profile", "email"],
          pkce: true,
          disableHttpsCheck: true,
        });
      }
    } catch (error) {
      console.error("Error sending company domain:", error);
    }
  };

  useEffect(() => {
    testFunction();
  }, []); // Empty array ensures this runs once

  return (
    <DomainSwitchContext.Provider value={{ logo }}>
      {children}
    </DomainSwitchContext.Provider>
  );
}
