import React from 'react';
import css from './style.module.css';

export default function ProgressIndicator() {
  return (
    <div className={`${css.indicatorContainer} my-3`}>
      <div className={css.row}>
        <div className={css.colMd3} >
          <div className={`${css.indicator} ${css.bodyArrow} d-flex align-items-center  justify-content-center`}>
            <p className='text-center text-dark m-0 fw-semibold text-white'>Awaiting Review</p>
            <div className={`${css.arrowRightStart}  `}>
            </div>
            <div className={`${css.arrowRight} ${css.borderArrow}`}></div>

          </div>
        </div>
        <div className={css.colMd3} >
          <div className={`${css.indicator} ${css.colorArrow} d-flex align-items-center  justify-content-center`}>
            <p className='text-center text-dark m-0 fw-semibold'>Pending Updates
            </p>
            <div className={`${css.arrowRightStart} `}>
            </div>
            <div className={`${css.arrowRight} `}></div>
          </div>
        </div>
        <div className={css.colMd3} >
          <div className={`${css.indicator}  d-flex align-items-center  justify-content-center`}>
            <p className='text-center text-dark m-0 fw-semibold'>Approved</p>
            <div className={`${css.arrowRightStart} `}>
            </div>
            <div className={`${css.arrowRight} `}></div>

          </div>
        </div>
      </div>
    </div>
  );
}
