import React from 'react';
import NavBar from '../NavBar/NavBar';
import VendorsTable from './VendorsTable';

export default function Vendors() {
    const userName = localStorage.getItem("User Name");
    const company = localStorage.getItem("CompanyName");
    const [pirmStatus, setPirmStatus] = "Pending";
    return (

        <div className="container-fluid ps-3 mt-3">
            <div className="row">
                <div className="col-md-4">
                    <h1 className="title">Vendor Portal</h1>
                </div>
                <p className='p-text '>Welcome <span>{userName}</span> to <span>{company}'s</span> Third-Party Portal. Here, you will be able to complete onboarding questionnaires as well as upload requested documents.</p>
                <p className='p-text fw-bold'>Open Assignments</p>
                {/* Show incomplete assignments as "Pending" */}
                <VendorsTable pirm_addon__Status__c={pirmStatus} setPirmStatus={setPirmStatus} />

                <p className='p-text fw-bold pt-5'>Completed Assignments</p>
                {/* Show completed assignments as "Completed" */}
                <VendorsTable pirm_addon__Status__c="Completed" />
            </div>
        </div>
    );
}
