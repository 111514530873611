import React, { useEffect, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../lib/firebase';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import css from './style.module.css';
import { useNavigate } from 'react-router-dom';

export default function FirebaseAuthForm({ loginStatus, setLoginStatus, session }) {
    const [userInfo, setUserInfo] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);  // State to toggle password visibility
    const [error, setError] = useState("");  // State to toggle password visibility
    const navigate = useNavigate();
    const roles = ["marketing", "compliance", "client"];
    const validationSchema = Yup.object({
        email: Yup.string().email().required('Email is required'),
        password: Yup.string().required('Password is required'),
    });


    useEffect(() => {
        if (userInfo?.role && roles.includes(userInfo.role)) {
            session();
            navigate('/home');
        }
    }, [userInfo]);

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                try {
                    setLoginStatus(true);

                    // Sign in user
                    const { email, password } = values;
                    const res = await signInWithEmailAndPassword(auth, email, password);
                    const user = res.user;
                    const Name = user.displayName ? user.displayName.split(',') : [];
                    localStorage.setItem("User Name", Name[0].trim());

                    // Fetch Firestore document
                    const docRef = doc(db, "users", user.uid); // Use imported db
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        setUserInfo(userData);
                    } else {
                        setLoginStatus(false);
                        setError("User not allowed to access this application");
                    }
                } catch (error) {
                    setLoginStatus(false);
                    setError(error.message);
                    console.error("Error signing in or fetching user data:", error);
                }
            }}

        >
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <div className="mb-3">
                        <Field
                            type="email"
                            name="email"
                            placeholder="Email"
                            className={`form-control ${css.placeholderPosition}`}
                        />
                        {errors.email && touched.email && (
                            <div className="text-danger text-start ms-1">{errors.email}</div>
                        )}
                    </div>

                    <div className="mb-3 position-relative">
                        <Field
                            type={passwordVisible ? 'text' : 'password'} // Toggle between text and password
                            name="password"
                            placeholder="Password"
                            className={`form-control ${css.placeholderPosition}`}
                        />
                        <div
                            className="position-absolute top-50 end-0 translate-middle-y pe-3"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setPasswordVisible(!passwordVisible)} // Toggle visibility
                        >
                            {passwordVisible ? (
                                <i className="fa-solid fa-eye-slash" />  // Font Awesome eye-slash icon
                            ) : (
                                <i className="fa-solid fa-eye" />  // Font Awesome eye icon
                            )}
                        </div>
                        {errors.password && touched.password && (
                            <div className="text-danger text-start ms-1">{errors.password}</div>
                        )}
                    </div>

                    <button
                        type="submit"
                        className={`btn text-white ${css.btnColor} rounded-1 w-100`}
                        disabled={isSubmitting || loginStatus} // Corrected logic
                    >
                        Sign In
                    </button>
                    {error && <p className="text-danger fw-bold mt-2 mb-0 w-100">{error}</p>}
                </Form>
            )}
        </Formik>
    );
}
