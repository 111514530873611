import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function MarketingComplianceTable({ data, status,tableTitel }) {

  const [marktingData, setMarktingData] = useState(null);

  useEffect(() => {
    if (data) {
      setMarktingData(data);
    }
  }, [data]);


  // Check if any item in the filtered data has Approved_by
  const hasApprovedBy = marktingData?.filter(item => item.status === status)
    .some(item => item.Approved_by);
  

  return (
    marktingData
      ?.filter(item => item.status === status).length > 0  ?
      <table className="table-bordered documents rounded-table p-0 w-100" style={{ marginTop: '20px' }}>
        <thead>
          <tr>
            <th scope="col" className="py-1 px-2">#</th>
            <th scope="col" className="py-1 px-2">Name</th>
            <th scope="col" className="py-1 px-2">Latest Version</th>
            <th scope="col" className="py-1 px-2">Created By</th>
            {hasApprovedBy && <th scope="col" className="py-1 px-2">Approved By</th>}
            <th scope="col" className="py-1 px-2">Status</th>
            <th scope="col" className="py-1 px-2">Date Assigned</th>
            {/* <th scope="col" className="py-1 px-2">Due Date</th> */}
          </tr>
        </thead>
        <tbody>
          {marktingData
            ?.filter(item => item.status === status)
            .map((item, index) => (
              <tr key={index}>
                <th scope="row" className="py-1 px-2 border">{index + 1}</th>
                <td className="py-1 px-2">
                  <Link
                    to={`/marketing/details/${item?.documentId}`}
                    className="text-decoration-none text-dark"
                    state={{ data: item }}
                  >
                    {item?.documentName}
                  </Link>
                </td>
                <td className="py-1 px-2">V{item?.latestVersion}</td>
                <td className="py-1 px-2">{item?.created_by}</td>
                {hasApprovedBy && <td className="py-1 px-2">{item?.Approved_by}</td>}
                <td className="py-1 px-2">{item?.status}</td>
                <td className="py-1 px-2">{item?.createdAt
                  .split("T")[0]}</td>
                {/* <td className="py-1 px-2">{item?.due_date}</td> */}
              </tr>
            ))}
        </tbody>
      </table> :
      <p className="ms-2">No {tableTitel} Assignments</p>
  );
}
