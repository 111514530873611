import React, { useContext, useEffect, useState } from 'react'
import MarketingComplianceTable from './Table';
import { motion } from "framer-motion";
import { MarktingContext } from '../../Context/MarktingContext';
import AddMarktingForm from './AddMarktingForm';

export default function MarketingCompliance() {
  const { marktingData, setMarktingData } = useContext(MarktingContext);
  const [markting, setMarkting] = useState(null);
  const [animationPlayed, setAnimationPlayed] = useState(false);


  useEffect(() => {
    if (marktingData) {
      setMarkting(marktingData);
    }
  }, [marktingData]);
  if (!markting) {
    return <p>Loading...</p>;
  }

  return (
      <div className="container-fluid mt-3 ms-3">
        <div className="row align-items-center">
          <h4 className="title ">Marketing Compliance</h4>
          <div className="col-md-12 ">
            <div className='d-flex align-items-end '>
              <p className='m-0 p-text fw-bold mx-2 '>Open Assignments</p>
              <div className='d-flex justify-content-center align-items-center holdCursor  mb-1' style={{ width: "20px", height: "20px", borderRadius: "50%", border: "2px solid #000" }} onClick={() => setAnimationPlayed(!animationPlayed)}>
                <motion.div
                  initial={{ rotate: 0 }}
                  animate={{ rotate: animationPlayed ? 45 : 0 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                  <i className="fa-solid fa-plus"></i>
                </motion.div>
              </div>
            </div>
            {/* markteing form */}
            <AddMarktingForm animationPlayed={animationPlayed} />
            {/* marketing table (In Progress)*/}
            <MarketingComplianceTable data={markting} tableTitel="Open Assignments" status="In Progress" />
          </div>
          <div className="col-md-12 ">
            <p className='p-text fw-bold ms-2'>Pending Approval</p>
            {/* marketing table (Pending)*/}

            <MarketingComplianceTable data={markting} tableTitel="Pending Approval" status="Pending" />
          </div>
          <div className="col-md-12 ">
            <p className='p-text fw-bold ms-2'>Approved</p>
            {/* marketing table (Approved) */}

            <MarketingComplianceTable data={markting} tableTitel="Approved" status="Approved" />
          </div>
        </div>
      </div>

  )
}
