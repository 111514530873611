import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const MarktingContext = createContext(null);

export default function MarktingProvider({ children }) {
  const [marktingData, setMarktingData] = useState([]);
  // const [status, setstatus] = useState(false);

  const fetchMarktingData = async () => {
    try {
      const { data } = await axios.get("https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/marketing");
      //   console.log("data :", data.data);
      // setstatus(true);
   
      
      setMarktingData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // The empty array ensures the effect runs only once after the component mounts
  useEffect(() => {
    fetchMarktingData();
  }, []); // Empty dependency array ensures this runs once

  return (
    <MarktingContext.Provider value={{ marktingData, setMarktingData }}>
      {children}
    </MarktingContext.Provider>
  );
}
