import * as Yup from "yup";

export const otpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please provide a valid email address.")
    .required("Email is required."),
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password is required."),
  otp: Yup.string()
    .length(4, "OTP must be exactly 4 digits.") // Ensure the OTP is exactly 4 characters long
    .matches(/^\d{4}$/, "OTP must be a 4-digit number.") // Ensure the OTP is numeric
    .required("OTP is required."),
});

// Regular expressions for common image, office, and PDF file types
const imageTypes = /^(image\/(jpeg|jpg|png|gif|bmp|webp|tiff))$/; // Matches common image formats
const officeTypes = /^(application\/(msword|vnd.openxmlformats-officedocument.wordprocessingml.document)|application\/(vnd.ms-excel|vnd.openxmlformats-officedocument.spreadsheetml.sheet)|application\/(vnd.ms-powerpoint|vnd.openxmlformats-officedocument.presentationml.presentation)|application\/pdf)$/; // Matches office files and PDFs

export const fileSchema = Yup.object({
  file: Yup.mixed()
    .required("A file is required")
    .test("file-type", "Only image, office document, or PDF files are allowed", (value) => {
      if (!value) return false; // No file uploaded

      const fileType = value.type;
      
      // Check if the file is an image, office document, or PDF
      return imageTypes.test(fileType) || officeTypes.test(fileType);
    })
    .test("file-size", "File size is too large", (value) => {
      if (!value) return true; // Skip size validation if no file
      return value.size <= 10 * 1024 * 1024; // 10MB size limit, adjust as necessary
    }),
});

