import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { createContext, useState } from "react";
import { Decryption } from "../lib/Decryption";
import { useLocation, useNavigate } from "react-router-dom";

export const vendorLoginContext = createContext(null);

export default function VendorLoginProvider({ children }) {
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const getFromStorage = (key) => {
    const dataString = localStorage.getItem(key);
    if (!dataString) return null;

    const data = JSON.parse(dataString);
    if (Date.now() > data.expiry) {
      if (location.pathname.split("/")[1] === "forgetpassword") {
        navigate("/otp");
        localStorage.removeItem(key); // Remove expired item
      } else localStorage.removeItem(key); // Remove expired item
      return null;
    }
    return data.value;
  };

  const [otpCodeNumber, setOtpCodeNumber] = useState(() => {
    // Retrieve the OTP from localStorage, ensuring it's valid
    const storedOtp = getFromStorage("Check"); // Use the correct key
    return storedOtp ? Decryption(storedOtp) : null; // Decrypt if valid, otherwise return null
  });
  const decodeToken = (token) => {
    try {
      const decodedToken = jwtDecode(String(token));

      return decodedToken;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  const compareUserId = (storedUserId) => {
    if (token) {
      const decoded = decodeToken(token);
      if (decoded && decoded.user_id) {
        localStorage.setItem("Vendor User", decoded.user_id);
        return storedUserId === decoded.user_id;
      }
    }
    return false;
  };

  const changePassword = async (email, password, otp, expiry) => {
    try {
      const response = await axios.post(
        `https://api-skhwrpqgyq-uc.a.run.app/chnagepassword/${otp}`,
        {
          email,
          password,
          expiry,
        }
      );

      localStorage.removeItem("Check");
      return response;
    } catch (error) {
      console.error("Error changing password:", error);
      throw error;
    }
  };

  const otpCode = async (email) => {
    const companyName = localStorage.getItem("CompanyName");
    // console.log(companyName);
    
    try {
      const response = await axios.post(
        "https://api-skhwrpqgyq-uc.a.run.app/otpcode",
        {
          email,
          companyName
        }
      );

      return response;
    } catch (error) {
      console.error("Error changing password:", error);
      throw error;
    }
  };

  return (
    <vendorLoginContext.Provider
      value={{
        token,
        setToken,
        decodeToken,
        compareUserId,
        userData,
        setUserData,
        changePassword,
        setOtpCodeNumber,
        otpCodeNumber,
        otpCode,
      }}
    >
      {children}
    </vendorLoginContext.Provider>
  );
}
