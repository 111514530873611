import React from 'react'
import css from './style.module.css'
export default function DocumentComments({ userName }) {
    return (
        <div className={` vh-100 container px-3 py-4 overflow-auto`}>
            <div className="row ">
                <h5 className={css.headerText}>Comments</h5>
                <h5 className={css.headerText}>feed.</h5>
                <div className="col-md-12">
                    {Array(6).fill(null).map((_, index) => (
                        <div key={index} className={css.commentBox}>
                            <div className='d-flex align-items-center mb-0'>
                                <i className="fa-regular fa-face-smile fa-xl me-2 text-center mt-1"></i>
                                <p className='m-0' style={{ fontSize: "21px" }}>Omar Hany</p>
                            </div>
                            <p className='mt-2 mb-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur sint facilis magni est impedit optio eveniet doloremque error vel fugit officia suscipit ab non sunt assumenda nemo autem, necessitatibus doloribus aperiam facere minus. Maiores, beatae.</p>
                            <p className='text-muted' style={{ fontSize: "15px" }}>January 21, 2025 1:23pm</p>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}
