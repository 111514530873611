import React, { useContext, useEffect, useRef, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import { VendorContext } from '../../Context/vendorContext';
import { useLocation } from 'react-router-dom';
import Questions from './Questions';
import css from './style.module.css';
import axios from 'axios';
import { SalesforceContext } from "../../Context/SF.CredantialsContext";

export default function Questionnaires() {
    const { tokens, fetchCredentials } = useContext(SalesforceContext);

    const location = useLocation();
    const { id, pirm_addon__Status__c, Audits_r } = location.state;

    const [questionnaire, setQuestionnaire] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [status, setStatus] = useState({});
    const [position, setPosition] = useState(0); // Active section
    const [currentIndex, setCurrentIndex] = useState(0); // Visible sections
    const [theAnswerdQuestion, setTheAnswerdQuestion] = useState(0);
    const [theTotalQuestion, setTheTotalQuestion] = useState(0);

    const isStatusInitialized = useRef(false); // To track if status is already initialized
    const visibleItems = questionnaire.slice(currentIndex, currentIndex + 3); // Show 3 sections at a time

    useEffect(() => {
        if (allQuestions) {
            setTheAnswerdQuestion(allQuestions?.filter(item => item.pirm_addon__QuestionType__c !== "Blocker" && item.pirm_addon__IsApplicable__c).filter(item => item.ContentDocumentLinks || item.pirm_addon__Answer__c).length);
            setTheTotalQuestion(allQuestions.filter(item => item.pirm_addon__IsApplicable__c && item.pirm_addon__QuestionType__c !== "Blocker").length);
        }
    }, [allQuestions]);


    const getVendorQuestionnaire = async (id, token) => {
        try {
            if (tokens) {
                const response = await axios.post(
                    `https://api-skhwrpqgyq-uc.a.run.app/questionnaire/${id}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const finalResult = response.data.data;
                finalResult.sort((a, b) => {
                    const aParentTemplate =
                        a.pirm_addon__Question__r?.pirm_addon__Template__r
                            ?.pirm_addon__Parent_Template__c;
                    const bParentTemplate =
                        b.pirm_addon__Question__r?.pirm_addon__Template__r
                            ?.pirm_addon__Parent_Template__c;

                    if (!aParentTemplate && bParentTemplate) return -1;
                    if (aParentTemplate && !bParentTemplate) return 1;
                    return 0;
                });

                return finalResult;
            }
        } catch (error) {
            if (
                error.response?.status === 401 &&
                error.response?.data?.message === "Token expired"
            ) {
                const refreshSuccess = await fetchCredentials();
                if (refreshSuccess) {
                    return await getVendorQuestionnaire(id, refreshSuccess);
                }
            } else {
                console.error("Error fetching questionnaire:", error.response?.data?.message || error.message);
            }
        }
    };

    useEffect(() => {
        const fetchQuestionnaire = async () => {
            if (!id) return;
            try {
                const data = await getVendorQuestionnaire(id, tokens);
                setAllQuestions(data);
            } catch (error) {
                console.error('Error fetching vendor questionnaire:', error);
            }
        };
        fetchQuestionnaire();
    }, [id]);

    useEffect(() => {
        if (!allQuestions || allQuestions.length === 0) return;

        const groupedData = allQuestions.reduce((acc, item) => {
            const key = item?.pirm_addon__Question__r?.pirm_addon__Template__r?.Name;
            if (!acc[key]) acc[key] = [];
            acc[key].push(item);
            return acc;
        }, {});

        setQuestionnaire(Object.values(groupedData));

        if (!isStatusInitialized.current) {
            const initialStatus = Object.keys(groupedData).reduce((acc, _, index) => {
                acc[index] = index === 0;
                return acc;
            }, {});
            setStatus(initialStatus);
            isStatusInitialized.current = true;
        }
    }, [allQuestions]);

    const handleClick = (index) => {
        setPosition(index); // Update the active section
    
        // Ensure the active section is visible in the progress bar
        if (index < currentIndex) {
            setCurrentIndex(index); // Move the progress bar to the left
        } else if (index >= currentIndex + 3) {
            setCurrentIndex(index - 2); // Move the progress bar to the right
        }
    
        // Update the status to highlight the active section
        setStatus(() => {
            const updatedStatus = {};
            Object.keys(status).forEach((key) => {
                updatedStatus[key] = parseInt(key) === index;
            });
            return updatedStatus;
        });
    };
    const handleNext = () => {
        if (currentIndex < questionnaire.length - 3) {
            setCurrentIndex(currentIndex + 1); // Move the visible sections to the right
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1); // Move the visible sections to the left
        }
    };
    const updateAnswer = (Question, selectedAnswer) => {
        // Create a new array where only the item with the matching Id is updated
        const updatedStatus = allQuestions?.map((item) => {
            if (item.Id === Question.Id) {
                // Update the 'pirm_addon__Answer__c' for the specific question
                return { ...item, pirm_addon__Answer__c: selectedAnswer };
            }
            return item; // Ensure all items are returned as is
        });

        // Replace the state with the updated array
        setAllQuestions(updatedStatus);


        return updatedStatus; // Optional, if you need to return the updated data
    };

    const TestClickUpdateMultSelect = (Question, selectedAnswer) => {
        // Map through allQuestions and update the answers
        const updatedQuestions = allQuestions?.map((item) => {
            if (item.Id === Question.Id) {
                // Multi-select logic: Toggle selected answer in array
                let updatedAnswers = Array.isArray(item.pirm_addon__Answer__c)
                    ? item.pirm_addon__Answer__c.includes(selectedAnswer)
                        ? item.pirm_addon__Answer__c.filter((ans) => ans !== selectedAnswer) // Remove
                        : [...item.pirm_addon__Answer__c, selectedAnswer] // Add
                    : [selectedAnswer]; // Initialize if not an array

                // Create a new object to update the answers
                return { ...item, pirm_addon__Answer__c: updatedAnswers.length > 0 ? updatedAnswers : null };
            }

            return item; // Return unmodified item if no change
        });

        // Update related question logic
        const updatedApplicable = updatedQuestions?.map((item) => {

            if (item.pirm_addon__Question__r.pirm_addon__Related_Question__c === Question.pirm_addon__Question__c) {
                const relatedAnswers = JSON.parse(item.pirm_addon__Question__r.pirm_addon__Related_Answers__c);
                const answerArray = updatedQuestions.find((q) => q.Id === Question.Id).pirm_addon__Answer__c || [];
                if (
                    item.pirm_addon__IsApplicable__c === true &&
                    !answerArray.some((answer) => relatedAnswers.includes(answer))
                ) {
                    return { ...item, pirm_addon__IsApplicable__c: false };
                }
                if (answerArray.some((answer) => relatedAnswers.includes(answer))) {
                    return { ...item, pirm_addon__IsApplicable__c: true };
                }
            }
            return item; // Return unmodified item
        });
        // Set the final state
        setAllQuestions(updatedApplicable); // Update the state with new answers and applicable status
    };

    const TestClickUpdate = (Question, selectedAnswer) => {

        const updatedClickStatus = allQuestions?.map((item) => {
            if (item.Id === Question.Id) {
                // Single-select logic: Ensure only one answer is selected
                item.pirm_addon__Answer__c = [selectedAnswer]; // Always store as an array, but only one answer
            }

            // Handle related question logic as before (if applicable)
            if (item.pirm_addon__Question__r.pirm_addon__Related_Question__c === Question.pirm_addon__Question__c) {
                if (
                    item.pirm_addon__IsApplicable__c === true &&
                    !JSON.parse(item.pirm_addon__Question__r.pirm_addon__Related_Answers__c).includes(selectedAnswer)
                ) {
                    return { ...item, pirm_addon__IsApplicable__c: false };
                }
                if (JSON.parse(item.pirm_addon__Question__r.pirm_addon__Related_Answers__c).includes(selectedAnswer)) {
                    return { ...item, pirm_addon__IsApplicable__c: true };
                }
            }

            return item;
        });

        setAllQuestions(updatedClickStatus); // Update the state with new answers
    };
    const DeleteFileParent = async (question, docId, tokens) => {
        if (!tokens || !docId || !question) return;

        try {
            // Make the DELETE request to the backend
            const response = await axios.delete(
                `https://api-skhwrpqgyq-uc.a.run.app/delete/file/${docId}`,
                {
                    headers: {
                        Authorization: `Bearer ${tokens}`,
                    },
                }
            );

            if (response.status === 200) {
                // Update the questions after successful deletion
                setAllQuestions((prevAllQuestions) => {
                    return prevAllQuestions.map((item) => {
                        // Check if the current item matches the question
                        if (item.Id === question.Id && item.ContentDocumentLinks && item.ContentDocumentLinks.records) {
                            // Remove the file from the records array
                            item.ContentDocumentLinks.records = item.ContentDocumentLinks.records.filter(
                                (file) => file.ContentDocument.Id !== docId
                            );
                            // Decrease the totalSize by 1
                            item.ContentDocumentLinks.totalSize -= 1;
                            // If no files left, set ContentDocumentLinks to null
                            if (item.ContentDocumentLinks.totalSize === 0) {
                                item.ContentDocumentLinks = null;
                            }
                        }
                        return item;
                    });
                });
            }
        } catch (error) {


            if (error.response && error.response.status === 401) {


                // Refresh the token
                const newToken = await fetchCredentials();


                // If token refresh is successful, retry the delete operation with the new token
                if (newToken) {
                    await DeleteFileParent(question, docId, newToken);
                } else {
                    console.error("Failed to refresh token.");
                }
            } else {
                console.error("An error occurred during file deletion:", error);
            }

        }
    };

    return (
        <div className="container-fluid ps-3 mt-3">
            <div className="row">
                <div>
                    <h1 className="title ms-4 ">Vendor Questionnaire</h1>
                    <p className="p-text fs-6 ps-4">
                        Please answer all questions to complete the vendor onboarding questionnaire.
                        Our vendor management team will reach out should there be any additional questions.
                        Please reach out your relationship manager if there are any questions regarding the questionnaire.
                    </p>
                    <div className='d-flex justify-content-center align-items-center'>
                        {questionnaire.length > 1 && (
                            <div className="border-top border-3 position-relative mt-5 mb-4 w-50 d-flex justify-content-between">
                                {visibleItems.map((e, index) => (
                                    <div key={index}>
                                        <div className="position-absolute bottom-50 translate-middle-x w-100">
                                            <p className="text-center ps-2 fs-6">
                                                {e[0]?.pirm_addon__Question__r?.pirm_addon__Template__r?.Name || 'Unnamed Template'}
                                            </p>
                                        </div>
                                        {/* Clickable circle indicators */}
                                        <div
                                            onClick={() => handleClick(currentIndex + index)}
                                            className="d-flex justify-content-center align-items-center position-absolute top-50 translate-middle-y"
                                            style={{
                                                width: '22px',
                                                height: '22px',
                                                borderRadius: '50%',
                                                background: '#fff',
                                                cursor: 'pointer'
                                            }}>
                                            <div
                                                className="d-flex justify-content-center align-items-center position-absolute top-50 translate-middle-y"
                                                style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    borderRadius: '50%',
                                                    background: status[currentIndex + index] ? '#0176D3' : '#fff'
                                                }}>
                                                <div
                                                    style={{
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '50%',
                                                        background: status[currentIndex + index] ? '#fff' : '#C9C9C9'
                                                    }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {questionnaire.length > 3 && (
                        <div className="d-flex justify-content-center fa-xl">
                            {/* Left arrow */}
                            <i
                                className={`fa-regular fa-circle-left holdCursor ms-4 ${currentIndex <= 0 ? 'text-muted' : ''}`}
                                onClick={currentIndex > 0 ? handlePrevious : null}
                                style={{
                                    cursor: currentIndex <= 0 ? 'not-allowed' : 'pointer',
                                    color: "#653ED1",
                                }}
                            ></i>

                            {/* Right arrow */}
                            <i
                                className={`fa-regular fa-circle-right holdCursor ms-4 ${currentIndex >= questionnaire.length - 3 ? 'text-muted' : ''}`}
                                onClick={currentIndex < questionnaire.length - 3 ? handleNext : null}
                                style={{
                                    cursor: currentIndex >= questionnaire.length - 3 ? 'not-allowed' : 'pointer',
                                    color: "#653ED1",
                                }}
                            ></i>
                        </div>
                    )}
                    <Questions
                        setAllQuestions={setAllQuestions}
                        questionnaire={questionnaire[position]?.filter(item => item.pirm_addon__IsApplicable__c)}
                        allQuestions={allQuestions}
                        position={position}
                        id={id}
                        Audits_r={Audits_r}
                        pirm_addon__Status__c={pirm_addon__Status__c}
                        TestClickUpdate={TestClickUpdate}
                        updateAnswer={updateAnswer}
                        DeleteFileParent={DeleteFileParent}
                        questionnaireLength={questionnaire.length}
                        handleClick={handleClick}
                        theAnswerdQuestion={theAnswerdQuestion}
                        theTotalQuestion={theTotalQuestion}
                        TestClickUpdateMultSelect={TestClickUpdateMultSelect}
                    />
                </div>
            </div>
        </div>
    );
}