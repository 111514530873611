import React, { useState, useEffect } from "react";
import css from "./style.module.css";

const Pagination = ({ polices, setData }) => {
  const itemsPerPage = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [visiblePages, setVisiblePages] = useState([1, 2, 3, 4, 5]); // Visible page numbers

  // Calculate total pages based on polices length and items per page
  const totalPages = Math.ceil(polices?.length / itemsPerPage);

  useEffect(() => {
    if (!polices || polices.length === 0) {
      return;
    }
    if (polices.length < 10) {
      setData(polices);
    } else {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedData = polices.slice(startIndex, endIndex);

      // Set the sliced data
      setData(paginatedData);
    }
    // Slice the data to show based on the current page
  }, [currentPage, polices, setData]);

  // Adjust visible pages based on the current page
  const updateVisiblePages = (newPage) => {
    let newVisiblePages = [];

    if (newPage <= 5) {
      // Show the first 5 pages when on the first few pages
      newVisiblePages = [1, 2, 3, 4, 5];
    } else if (newPage > totalPages - 5) {
      // If on the last few pages, show the last 5 pages
      newVisiblePages = [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    } else {
      // For pages in the middle, show 5 pages centered around the current page
      const startPage = newPage - 2;
      newVisiblePages = [startPage, startPage + 1, startPage + 2, startPage + 3, startPage + 4];
    }

    setVisiblePages(newVisiblePages);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      updateVisiblePages(newPage);
    }
  };

  const firstpage = () => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }
  const lastpage = () => {
    setCurrentPage(totalPages);
    updateVisiblePages(totalPages);
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      updateVisiblePages(newPage);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };
  return (
    <>
      {polices.length < 10 ? "" :
        <nav aria-label="Page navigation">
          <ul className="pagination">
            {/* first page */}
            {currentPage > 1 && (
              <li
                onClick={firstpage}
                className={`page-item ${css.arrow} holdCursor`}
              >
                <i
                  tabIndex="-1"
                  style={{ color: "#691DF8" }}
                  className="fa-solid fa-angles-left"
                ></i>

              </li>
            )}
            {/* Previous button (left arrow) */}
            {currentPage > 1 && (
              <li
                onClick={handlePrev}
                className={`page-item ${css.arrow} holdCursor mx-2`}
              >
                <i
                  tabIndex="-1"
                  style={{ color: "#691DF8" }}
                  className="fa-solid fa-chevron-left"
                ></i>
              </li>
            )}

            {/* Display page numbers */}
            {visiblePages.map((page) => (
              page <= totalPages && (
                <li
                  key={page}
                  className={`page-item ${currentPage === page ? "active holdCursor" : "holdCursor"}`}
                >
                  <p
                    className={`${css.arrow} ms-2 ${currentPage === page ? `${css.activePage} active holdCursor` : "holdCursor"}`}
                    onClick={() => handlePageClick(page)}
                  >
                    {page}
                  </p>
                </li>
              )
            ))}

            {/* Next button (right arrow) */}
            {currentPage < totalPages && (
              <li
                onClick={handleNext}
                className={`page-item ${css.arrow} holdCursor mx-2`}
              >
                <i
                  tabIndex="-1"
                  style={{ color: "#691DF8" }}
                  className="fa-solid fa-chevron-right"
                ></i>
              </li>
            )}
            {/* last page */}
            {currentPage < totalPages && (
              <li
                onClick={lastpage}
                className={`page-item ${css.arrow} holdCursor`}
              >
                <i
                  tabIndex="-1"
                  style={{ color: "#691DF8" }}
                  className="fa-solid fa-angles-right"
                ></i>
              </li>
            )}
          </ul>
        </nav>
      }
    </>
  );
};

export default Pagination;
